import "./Header.css";

import { Link } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import React from "react";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";

import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import ListItemIcon from "@material-ui/core/ListItemIcon";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

import { ReactComponent as ProfileSvg } from "./profile.svg";
import { ReactComponent as Hamburger } from "./hamburger.svg";
import LogoWhite from "./logo-white.webp";
import Logo from "./logo.webp";

const useStyles = makeStyles({
  list: {
    width: 280,
  },
  fullList: {
    width: "auto",
  },
});

const Header = ({ auth, setHeaderHeight, active, setActive }) => {
  let user = JSON.parse(localStorage.getItem("user"));

  const Rheight = useRef(null);
  useEffect(() => {
    let heightCurrent = Rheight.current.offsetHeight;
    const timeout = setTimeout(() => {
      heightCurrent = Rheight.current.offsetHeight;
      setHeaderHeight(heightCurrent);
    }, 100);
    const handleWindowResize = () => {
      heightCurrent = Rheight.current.offsetHeight;
      setHeaderHeight(heightCurrent);
    };
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
      clearInterval(timeout);
    };
  }, [Rheight, setHeaderHeight]);
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  let flag = false;

  if (active === "video" || active === "podcast" || active === "article") {
    flag = true;
  }

  const [openT, setOpenT] = useState(flag);

  const handleClick = () => {
    setOpenT(!openT);
  };

  const classes = useStyles();
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onKeyDown={toggleDrawer("right", false)}
    >
      <Link
        to="/"
        onClick={(e) => {
          toggleDrawer("right", false)(e);
          setActive("");
        }}
        className="LogoMobileHeader"
      >
        <List className="collapseHeaderTop">
          <ListItem
            key={"Anahita Asadi Pooya"}
            className="collapseHeaderContentMain"
          >
            <ListItemText
              primary={"Anahita Asadi Pooya"}
              className="collapseHeaderContent"
            />
            <ListItemIcon className="collapseHeaderContentLogo">
              <img className="collapseHeaderBrandLogo" src={LogoWhite} alt="" />
            </ListItemIcon>
          </ListItem>
        </List>
      </Link>
      <List component="nav" className={classes.root}>
        <Link
          to="/"
          style={{ textDecoration: "none" }}
          onClick={(e) => {
            toggleDrawer("right", false)(e);
            setActive("");
          }}
        >
          <ListItem
            button
            // style={
            //   active === "" || active === "http:" ? { backgroundColor: "rgba(0, 15, 26,0.15)" } : null
            // }
          >
            <ListItemText
              primary="صفحه اصلی"
              className="collapseHeaderMainTitle"
              style={active === "" ? { color: "#FFBF0F" } : null}
            />
          </ListItem>
        </Link>
        <ListItem
          button
          onClick={handleClick}
          style={openT ? { backgroundColor: "rgba(0, 15, 26,0.15)" } : null}
        >
          <ListItemText
            primary="مدیا"
            className="collapseHeaderMainTitle"
            style={
              openT ||
              active === "article" ||
              active === "video" ||
              active === "podcast"
                ? { color: "#FFBF0F" }
                : null
            }
          />
          {openT ? (
            <ExpandLess style={{ color: "#FFBF0F" }} />
          ) : (
            <ExpandMore
              style={
                active === "article" ||
                active === "video" ||
                active === "podcast"
                  ? { color: "#FFBF0F" }
                  : { color: "white" }
              }
            />
          )}
        </ListItem>
        <Collapse in={openT} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <Link
              to="/video"
              style={{ textDecoration: "none" }}
              onClick={(e) => {
                toggleDrawer("right", false)(e);
                setActive("video");
              }}
            >
              <ListItem
                button
                className={classes.nested}
                // style={
                //   active === "video"
                //     ? { backgroundColor: "rgba(0, 15, 26,0.15)" }
                //     : null
                // }
              >
                <ListItemIcon></ListItemIcon>
                <ListItemText
                  primary="ویدئو"
                  className="collapseHeaderMainTitle"
                  style={active === "video" ? { color: "#FFBF0F" } : null}
                />
              </ListItem>
            </Link>
            <Link
              to="/article"
              style={{ textDecoration: "none" }}
              onClick={(e) => {
                toggleDrawer("right", false)(e);
                setActive("article");
              }}
            >
              <ListItem
                button
                className={classes.nested}
                // style={
                //   active === "article"
                //     ? { backgroundColor: "rgba(0, 15, 26,0.15)" }
                //     : null
                // }
              >
                <ListItemIcon></ListItemIcon>
                <ListItemText
                  primary="مقاله"
                  className="collapseHeaderMainTitle"
                  style={active === "article" ? { color: "#FFBF0F" } : null}
                />
              </ListItem>
            </Link>
            <Link
              to="/podcast"
              style={{ textDecoration: "none" }}
              onClick={(e) => {
                toggleDrawer("right", false)(e);
                setActive("podcast");
              }}
            >
              <ListItem
                button
                className={classes.nested}
                // style={
                //   active === "podcast"
                //     ? { backgroundColor: "rgba(0, 15, 26,0.15)" }
                //     : null
                // }
              >
                <ListItemIcon></ListItemIcon>
                <ListItemText
                  primary="پادکست"
                  className="collapseHeaderMainTitle"
                  style={active === "podcast" ? { color: "#FFBF0F" } : null}
                />
              </ListItem>
            </Link>
          </List>
        </Collapse>
        <Link
          to="/service"
          style={{ textDecoration: "none" }}
          onClick={(e) => {
            toggleDrawer("right", false)(e);
            setActive("service");
          }}
        >
          <ListItem
            button
            // style={
            //   active === "service"
            //     ? { backgroundColor: "rgba(0, 15, 26,0.15)" }
            //     : null
            // }
          >
            <ListItemText
              primary="خدمات مشاوره"
              className="collapseHeaderMainTitle"
              style={active === "service" ? { color: "#FFBF0F" } : null}
            />
          </ListItem>
        </Link>
        <Link
          to="/about-us"
          style={{ textDecoration: "none" }}
          onClick={(e) => {
            toggleDrawer("right", false)(e);
            setActive("about-us");
          }}
        >
          <ListItem
            button
            // style={
            //   active === "service"
            //     ? { backgroundColor: "rgba(0, 15, 26,0.15)" }
            //     : null
            // }
          >
            <ListItemText
              primary="درباره‌ی من"
              className="collapseHeaderMainTitle"
              style={active === "about-us" ? { color: "#FFBF0F" } : null}
            />
          </ListItem>
        </Link>
        {auth ? (
          <Link
            to="/profile"
            style={{ textDecoration: "none" }}
            onClick={(e) => {
              toggleDrawer("right", false)(e);
              setActive("profile");
            }}
          >
            <ListItem button className="collapseHeaderProfileContainer">
              <ListItemIcon className="collapseHeaderProfileSvgContainer">
                <ProfileSvg
                  className="collapseHeaderProfileSvg"
                  fill={active === "profile" ? "#FFBF0F" : "#c4c4c4"}
                  stroke={active === "profile" ? "#FFBF0F" : "#c4c4c4"}
                />
              </ListItemIcon>
              <ListItemText
                primary={
                  user && user.user !== null && user.user !== ""
                    ? user.user
                    : "پروفایل"
                }
                className="collapseHeaderMainTitle"
                style={active === "profile" ? { color: "#FFBF0F" } : null}
              />
            </ListItem>
          </Link>
        ) : (
          <Link
            to="/sign-in"
            style={{ textDecoration: "none" }}
            onClick={(e) => {
              toggleDrawer("right", false)(e);
              setActive("sign-in");
            }}
          >
            <ListItem button className="collapseHeaderProfileContainer">
              {/* <ListItemIcon className="collapseHeaderProfileSvgContainer">
                <ProfileSvg
                  className="collapseHeaderProfileSvg"
                  fill={active === "sign-up" ? "#FFBF0F" : "#c4c4c4"}
                  stroke={active === "sign-up" ? "#FFBF0F" : "#c4c4c4"}
                />
              </ListItemIcon> */}
              <ListItemText
                primary="ورود / ثبت نام"
                className="collapseHeaderMainTitle"
                style={
                  active === "sign-up" ||
                  active === "sign-in" ||
                  active === "forget-password"
                    ? { color: "#FFBF0F" }
                    : null
                }
              />
            </ListItem>
          </Link>
        )}
      </List>
    </div>
  );

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <div className="header" ref={Rheight}>
      <div className="containerHeader">
        <Link to="/" className="logoHeader" onClick={() => setActive("")}>
          <img className="logoHeaderImg" src={Logo} alt="AP logo" />
        </Link>
        <div className="linksHeader">
          <Link
            className={`${active === "" ? "active" : ""}titleHeader`}
            onClick={() => setActive("")}
            to="/"
          >
            صفحه اصلی
          </Link>
          <Button
            ref={anchorRef}
            aria-controls={open ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
            style={{ padding: "0" }}
          >
            <p
              className={`${
                active === "article" ||
                active === "video" ||
                active === "podcast"
                  ? "active"
                  : ""
              }titleHeader`}
            >
              مدیا
            </p>
          </Button>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
            placement="bottom-end"
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom",
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      autoFocusItem={open}
                      id="menu-list-grow"
                      onKeyDown={handleListKeyDown}
                    >
                      <MenuItem onClick={handleClose}>
                        <Link
                          className={`${
                            active === "video" ? "active" : ""
                          }titleHeader`}
                          onClick={() => setActive("video")}
                          to="/video"
                        >
                          ویدئو
                        </Link>
                      </MenuItem>
                      <MenuItem onClick={handleClose}>
                        <Link
                          className={`${
                            active === "article" ? "active" : ""
                          }titleHeader`}
                          onClick={() => setActive("article")}
                          to="/article"
                        >
                          مقاله
                        </Link>
                      </MenuItem>
                      <MenuItem onClick={handleClose}>
                        <Link
                          className={`${
                            active === "podcast" ? "active" : ""
                          }titleHeader`}
                          onClick={() => setActive("podcast")}
                          to="/podcast"
                        >
                          پادکست
                        </Link>
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
          <Link
            className={`${active === "service" ? "active" : ""}titleHeader`}
            onClick={() => setActive("service")}
            to="/service"
          >
            خدمات مشاوره
          </Link>
          <Link
            className={`${active === "about-us" ? "active" : ""}titleHeader`}
            onClick={() => setActive("about-us")}
            to="/about-us"
            style={{ marginRight: "0" }}
          >
            درباره‌ی من
          </Link>
        </div>
        {auth ? (
          <Link
            className="profileHeaderContainer"
            onClick={() => setActive("profile")}
            to="/profile"
          >
            <div className="profileHeaderTitleContainer">
              <p
                className={`${
                  active === "profile" ? "active" : ""
                }profileHeaderTitle`}
              >
                {user && user.user !== null && user.user !== ""
                  ? user.user
                  : "پروفایل"}
              </p>
            </div>
            <div className="profileHeaderSvgContainer">
              <ProfileSvg
                className="profileHeaderSvg"
                fill={active === "profile" ? "#FFBF0F" : "#c4c4c4"}
                stroke={active === "profile" ? "#FFBF0F" : "#c4c4c4"}
              />
            </div>
          </Link>
        ) : (
          <Link
            className="profileHeaderContainer"
            onClick={() => setActive("sign-in")}
            to="/sign-in"
          >
            <div className="profileHeaderTitleContainer2">
              <p
                className={`${
                  active === "sign-up" ||
                  active === "sign-in" ||
                  active === "forget-password"
                    ? "active"
                    : ""
                }profileHeaderTitle`}
              >
                ورود / ثبت نام
              </p>
            </div>
          </Link>
        )}
      </div>
      <div className="headerMobile">
        <Link
          to="/"
          className="headerMobileLogoContainer"
          onClick={() => setActive("")}
        >
          <img className="headerMobileLogo" src={Logo} alt="ap logo img" />
        </Link>
        <Button
          onClick={toggleDrawer("right", true)}
          className="hamburgerHeaderMobileImgContainer"
        >
          <Hamburger className="hamburgerHeaderMobileImg" />
        </Button>
        <Drawer
          anchor={"right"}
          open={state["right"]}
          onClose={toggleDrawer("right", false)}
        >
          {list("right")}
        </Drawer>
      </div>
    </div>
  );
};

export default Header;
