import "./Footer.css";
import { Link } from "react-router-dom";

import { useEffect, useState } from "react";

import { ReactComponent as Instagram } from "./instagram-small.svg";
import { ReactComponent as Mail } from "./mail.svg";
import { ReactComponent as Twitter } from "./twitter-small.svg";
import { ReactComponent as LinkedIn } from "./linkedin-small.svg";
import RubenoLogo from "./rubeno-logo.webp";

const Footer = () => {
  const [dataFooter, setDataFooter] = useState([]);

  let user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    async function fetchDataVideo() {
      const formData = new FormData();
      formData.append("type", "contact");
      const response = await fetch(
        "https://drasadipooya.com/api/content/content.php",
        {
          method: "POST",
          body: formData,
        },
      );
      const res = await response.json();
      setDataFooter(JSON.parse(res.data[0].text));
    }

    fetchDataVideo();
  }, []);

  return (
    <div className="footer" id="footer">
      <div className="footerNMobile">
        <div className="section1Footer">
          <div className="containerSection1Footer">
            <div className="infoFooter">
              <div className="emailFooter">
                <div className="emailLogo">
                  <Mail className="emailLogoImg" />
                  {/* <img
                    className="emailLogoImg"
                    src="./assets/Mail/mail.svg"
                    alt="email icon"
                  /> */}
                </div>
                <div className="textContainerEmailFooter">
                  <p className="textEmailFooter">{dataFooter?.mail}</p>
                </div>
              </div>
              <div className="telFooter">
                <p className="telTextFooter">Tel.: {dataFooter?.phone}</p>
              </div>
              <div className="addressFooter">
                <p className="textAddressFooter">{dataFooter?.address}</p>
              </div>
            </div>
            <div className="navFooter">
              <div className="segFooter">
                <p className="titleFooter"> ای پی کلینیک </p>
                <Link to="/video" className="linkFooter">
                  آرشیو ویدئو
                </Link>
                <Link to="/podcast" className="linkFooter">
                  آرشیو پادکست
                </Link>
                <Link to="/article" className="linkFooter">
                  آرشیو مقالات
                </Link>
              </div>
              <div className="segFooter">
                <p className="titleFooter"> خدمات </p>
                <Link to="/service" className="linkFooter">
                  مشاوره
                </Link>
              </div>
              <div className="segFooter">
                <p className="titleFooter"> ارتباط با ما </p>
                <Link to="/about-us" className="linkFooter">
                  درباره ی ما
                </Link>
                {user === null ? (
                  <>
                    <Link to="/sign-up" className="linkFooter">
                      عضویت
                    </Link>
                    <Link to="/sign-in" className="linkFooter">
                      ورود
                    </Link>{" "}
                  </>
                ) : (
                  <Link to="/profile" className="linkFooter">
                    پروفایل
                  </Link>
                )}
              </div>
              <div className="culFollowFooter">
                <div className="titleFollowFooter">
                  <p className="textTitleFollowFooter"> Follow us </p>
                </div>
                <div className="rowDCulFollowFppter">
                  <a
                    href="https://www.instagram.com/dr.asadipooya/"
                    target="_blank"
                    rel="noreferrer"
                    className="followSegFooter"
                  >
                    <div className="followSegFooterImgContainer">
                      <Instagram className="followSegFooterImg" />
                      {/* <img
                        src="./instagram-small.svg"
                        alt="website logo"
                        className="followSegFooterImg"
                      /> */}
                    </div>
                    <div className="followSegFootertext">
                      <p className="linkFollowFooter"> Instagram </p>
                    </div>
                  </a>
                  <a
                    href="https://twitter.com/AAsadipooya"
                    target="_blank"
                    rel="noreferrer"
                    className="followSegFooter"
                  >
                    <div className="followSegFooterImgContainer">
                      <Twitter className="followSegFooterImg" />
                      {/* <img
                        src="./assets/Twitter/twitter-small.svg"
                        alt="website logo"
                        className="followSegFooterImg"
                      /> */}
                    </div>
                    <div className="followSegFootertext">
                      <p className="linkFollowFooter"> Twitter </p>
                    </div>
                  </a>
                  <a
                    href="https://ir.linkedin.com/in/anahita-asadipooya-abaa28207"
                    target="_blank"
                    rel="noreferrer"
                    className="followSegFooter"
                  >
                    <div className="followSegFooterImgContainer">
                      <LinkedIn className="followSegFooterImg" />
                      {/* <img
                        src="./assets/YouTube/youtube-small.svg"
                        alt="website logo"
                        className="followSegFooterImg"
                      /> */}
                    </div>
                    <div className="followSegFootertext">
                      <p className="linkFollowFooter"> LinkedIn </p>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section2Footer">
          <div className="containerSection2Footer">
            <div className="rubenoContainerImg">
              <img src={RubenoLogo} alt="rubeno logo" className="rubenoImg" />
            </div>
            <div className="textContainerSection2Footer">
              <p className="textSection2Footer">www.rubeno.co - 2020 - 2021</p>
            </div>
          </div>
        </div>
      </div>
      <div className="footerMobile">
        <div className="section1Footer">
          <div className="containerSection1Footer">
            <div className="infoFooter">
              <div className="addressFooter">
                <p className="textAddressFooter">{dataFooter?.address}</p>
              </div>
            </div>
            <div className="MiddleFooterMobile">
              <div className="emailFooter">
                <div className="emailLogo">
                  <Mail className="emailLogoImg" />
                </div>
                <div className="textContainerEmailFooter">
                  <p className="textEmailFooter">{dataFooter?.mail}</p>
                </div>
              </div>
              <div className="telFooter">
                <p className="telTextFooter">Tel.: {dataFooter?.phone}</p>
              </div>
            </div>
            <div className="navFooter">
              <div className="segFooter">
                <p className="titleFooter"> ای پی کلینیک </p>
                <Link to="/video" className="linkFooter">
                  آرشیو ویدئو
                </Link>
                <Link to="/podcast" className="linkFooter">
                  آرشیو پادکست
                </Link>
                <Link to="/article" className="linkFooter">
                  آرشیو مقالات
                </Link>
              </div>
              <div className="segFooter">
                <p className="titleFooter"> خدمات </p>
                <Link to="/service" className="linkFooter">
                  مشاوره
                </Link>
              </div>
              <div className="segFooter">
                <p className="titleFooter"> ارتباط با ما </p>
                <Link to="/about-us" className="linkFooter">
                  درباره ی ما
                </Link>
                {user === null ? (
                  <>
                    <Link to="/sign-up" className="linkFooter">
                      عضویت
                    </Link>
                    <Link to="/sign-in" className="linkFooter">
                      ورود
                    </Link>{" "}
                  </>
                ) : (
                  <Link to="/profile" className="linkFooter">
                    پروفایل
                  </Link>
                )}
              </div>
              <div className="culFollowFooter">
                <div className="titleFollowFooter">
                  <p className="textTitleFollowFooter"> Follow us </p>
                </div>
                <div className="rowDCulFollowFppter">
                  <a
                    href="https://www.instagram.com/dr.asadipooya/"
                    target="_blank"
                    rel="noreferrer"
                    className="followSegFooter"
                  >
                    <div className="followSegFooterImgContainer">
                      <Instagram className="followSegFooterImg" />
                      {/* <img
                        src="./assets/Instagram/instagram-small.svg"
                        alt="website logo"
                        className="followSegFooterImg"
                      /> */}
                    </div>
                    <div className="followSegFootertext">
                      <p className="linkFollowFooter"> Instagram </p>
                    </div>
                  </a>
                  <a
                    href="https://twitter.com/AAsadipooya"
                    target="_blank"
                    rel="noreferrer"
                    className="followSegFooter"
                  >
                    <div className="followSegFooterImgContainer">
                      <Twitter className="followSegFooterImg" />
                      {/* <img
                        src="./assets/Twitter/twitter-small.svg"
                        alt="website logo"
                        className="followSegFooterImg"
                      /> */}
                    </div>
                    <div className="followSegFootertext">
                      <p className="linkFollowFooter"> Twitter </p>
                    </div>
                  </a>
                  <a
                    href="https://ir.linkedin.com/in/anahita-asadipooya-abaa28207"
                    target="_blank"
                    rel="noreferrer"
                    className="followSegFooter"
                  >
                    <div className="followSegFooterImgContainer">
                      <LinkedIn className="followSegFooterImg" />
                      {/* <img
                        src="./assets/YouTube/youtube-small.svg"
                        alt="website logo"
                        className="followSegFooterImg"
                      /> */}
                    </div>
                    <div className="followSegFootertext">
                      <p className="linkFollowFooter"> LinkedIn </p>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section2Footer">
          <div className="containerSection2Footer">
            <div className="rubenoContainerImg">
              <img src={RubenoLogo} alt="rubeno logo" className="rubenoImg" />
            </div>
            <div className="textContainerSection2Footer">
              <p className="textSection2Footer">www.rubeno.co - 2020 - 2021</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
