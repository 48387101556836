import React, { Suspense, lazy, useState, useEffect } from "react";

import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
  Redirect,
} from "react-router-dom";

import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";
import ScrollToTop from "./ScrollToTop";

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

import Helmet from "react-helmet";

const Landing = lazy(() => import("./Pages/Landing/Landing"));
const Video = lazy(() => import("./Pages/Video/Video"));
const Article = lazy(() => import("./Pages/Article/Article"));
const Podcast = lazy(() => import("./Pages/Podcast/Podcast"));
const VideoInner = lazy(() => import("./Pages/VideoInner/VideoInner"));
const ArticleInner = lazy(() => import("./Pages/ArticleInner/ArticleInner"));
const PodcastInner = lazy(() => import("./Pages/PodcastInner/PodcastInner"));
const Profile = lazy(() => import("./Pages/Profile/Profile"));
const SignIn = lazy(() => import("./Pages/SignIn/SignIn"));
const SignUp = lazy(() => import("./Pages/SignUp/SignUp"));
const Service = lazy(() => import("./Pages/Service/Service"));
const ServiceInner = lazy(() => import("./Pages/ServiceInner/ServiceInner"));
const NotFound = lazy(() => import("./Pages/NotFound/NotFound"));
const ForgetPassword = lazy(() =>
  import("./Pages/ForgetPassword/ForgetPassword"),
);
const AboutUs = lazy(() => import("./Pages/AboutUs/AboutUs"));

function App() {
  const [HeaderHeight, setHeaderHeight] = useState(0);

  let history = useHistory();

  const [active, setActive] = useState(window.location.href.split("/")[3]);

  let user = localStorage.getItem("user");
  if (user === undefined || user === null) {
    user = false;
  }
  const [auth, setAuth] = useState(user);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [websiteData, setWebsiteData] = useState([]);

  useEffect(() => {
    let user = localStorage.getItem("user");
    setAuth(user);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem("user")]);

  useEffect(() => {
    setActive(window.location.href.split("/")[3]);
  }, [active]);

  useEffect(() => {
    async function fetchDataWebsite() {
      const formData = new FormData();
      const response = await fetch(
        "https://drasadipooya.com/api/content/settings.php",
        {
          method: "POST",
          body: formData,
        },
      );
      const res = await response.json();
      setWebsiteData(res.data[0]);
    }

    fetchDataWebsite();
  }, []);

  return (
    <>
      <Helmet>
        <title>{websiteData?.title}</title>
        <meta name="description" content={websiteData?.des} />
        <meta name="keywords" content={websiteData?.keyw} />
      </Helmet>
      <div className="App">
        {/* <Router basename="/" hashType="noslash"> */}
        <Router basename="/">
          <Suspense
            fallback={
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100vh",
                }}
              >
                <Loader
                  type="Puff"
                  color="#FFBF0F"
                  height={100}
                  width={100}
                  timeout={100} //3 secs
                />
              </div>
            }
          >
            <ScrollToTop setActive={setActive} />
            <Header
              auth={auth}
              history={history}
              setHeaderHeight={setHeaderHeight}
              active={active}
              setActive={setActive}
            />
            <div className="containerApp">
              <Switch>
                <Route path="/video/:titleUrl">
                  <VideoInner />
                </Route>
                <Route path="/video">
                  <Video />
                </Route>
                <Route path="/article/:titleUrl">
                  <ArticleInner />
                </Route>
                <Route path="/article">
                  <Article />
                </Route>
                <Route path="/podcast/:titleUrl">
                  <PodcastInner />
                </Route>
                <Route path="/podcast">
                  <Podcast />
                </Route>
                <Route path="/service/:titleUrl">
                  <ServiceInner />
                </Route>
                <Route path="/service">
                  <Service />
                </Route>
                <Route path="/about-us">
                  <AboutUs />
                </Route>
                <Route
                  path="/sign-in"
                  render={({ location }) =>
                    !auth ? (
                      <SignIn
                        email={email}
                        setEmail={setEmail}
                        password={password}
                        setPassword={setPassword}
                      />
                    ) : (
                      <Redirect
                        to={{
                          pathname: "/",
                          state: { from: location },
                        }}
                      />
                    )
                  }
                />
                <Route
                  path="/sign-up"
                  render={({ location }) =>
                    !auth ? (
                      <SignUp />
                    ) : (
                      <Redirect
                        to={{
                          pathname: "/",
                          state: { from: location },
                        }}
                      />
                    )
                  }
                />
                <Route
                  path="/profile"
                  render={({ location }) =>
                    auth ? (
                      <Profile setAuth={setAuth} />
                    ) : (
                      <Redirect
                        to={{
                          pathname: "/",
                          state: { from: location },
                        }}
                      />
                    )
                  }
                />
                <Route path="/forget-password">
                  <ForgetPassword />
                </Route>
                <Route exact path="/">
                  <Landing HeaderHeight={HeaderHeight} />
                </Route>
                <Route path="*">
                  <NotFound />
                </Route>
              </Switch>
            </div>
            <Footer />
          </Suspense>
        </Router>
      </div>
    </>
  );
}

export default App;
