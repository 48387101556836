import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./Fonts/font.css";

global.BASE_URL = "https://drasadipooya.com/";

ReactDOM.render(<App />, document.getElementById("root"));

reportWebVitals();
